@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-indicator {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-indicator div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-indicator div:nth-child(1) {
  left: 8px;
  animation: loading 0.6s infinite;
}

.loading-indicator div:nth-child(2) {
  left: 8px;
  animation: loading 0.6s infinite;
}

.loading-indicator div:nth-child(3) {
  left: 32px;
  animation: loading 0.6s infinite;
}

.loading-indicator div:nth-child(4) {
  left: 56px;
  animation: loading 0.6s infinite;
}

@keyframes loading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
